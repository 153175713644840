import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";

// Callback function for redirecting after login
const onRedirectCallback = (appState) => {
  history.push(
    appState?.returnTo || window.location.pathname
  );
};

const config = getConfig();

// Get query parameters safely
const urlParams = new URLSearchParams(window.location.search);
const invitation = urlParams.get('invitation');
const organization = urlParams.get('organization');

// Configuration for Auth0Provider
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    ...(invitation ? { invitation } : {}),
    ...(organization ? { organization } : {}),
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : {}),
    scope: 'openid profile email offline_access',
  },
};

// Render the application
const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
);

// Register the service worker for offline capability
serviceWorker.unregister();
